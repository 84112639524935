import Cookies from 'js-cookie';

import { useHasAnyMedicationAvailability } from '@hooks/useHasAnyMedicationAvailability';
import { H5 } from '@common/headline';
import { TakeOurQuizButton } from '../Hero/TakeOurQuizCTA';
import Icon from '@common/Icons/Icon';

type Props = {
  utmMedium: string;
  utmCampaign: string;
};

export const MedicationStockBanner = ({
  utmMedium,
  utmCampaign,
}: Props): JSX.Element | null => {
  const hasAnyMedicationAvailability = useHasAnyMedicationAvailability();

  if (!hasAnyMedicationAvailability) {
    return null;
  }

  const getUTMContent = () => {
    const homePageTestBucket = Cookies.get('bucket-home');

    if (homePageTestBucket === 'b') {
      return 'homepage_banner_med1b';
    } else if (homePageTestBucket === 'c') {
      return 'homepage_banner_med1c';
    }

    return 'homepage_banner_med';
  };

  return (
    <div className="flex justify-center px-4 pb-0 pt-3 md:pb-10 md:pt-6 lg:pb-0">
      <div className="flex h-fit flex-col items-center justify-center gap-4 rounded-2xl bg-salmon-200 px-3 py-4 text-blue md:h-[48px] md:flex-row md:rounded-full md:py-7">
        <div className="flex items-center gap-x-4 md:items-start">
          <div className="flex items-start justify-center gap-x-2 md:items-center">
            <div className="mr-2 flex min-h-[40px] min-w-[40px] items-center justify-center rounded-full bg-[#9DE5DB]">
              <Icon name="calendar" />
            </div>
            <H5>
              Interested in our medication-supported weight-loss programme?
            </H5>
          </div>
        </div>
        <div className="flex w-full flex-1">
          <div className="w-full md:hidden">
            <TakeOurQuizButton
              ctaText="Check my eligibility"
              isGlp1
              fullWidth
              colour="NavyBlue"
              type="Primary"
              location="Homepage Medication Stock Banner"
              utmCampaign={utmCampaign}
              utmMedium={utmMedium}
              utmSource="organic"
              utmContent={getUTMContent()}
            />
          </div>
          <div className="hidden w-full md:block">
            <TakeOurQuizButton
              ctaText="Check my eligibility"
              colour="NavyBlue"
              type="Primary"
              isGlp1
              isSmall
              location="Homepage Medication Stock Banner"
              utmCampaign={utmCampaign}
              utmMedium={utmMedium}
              utmSource="organic"
              utmContent={getUTMContent()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
