import Image, { StaticImageData } from 'next/image';

import { BodySmall } from '@common/body';
import { H4, H6 } from '@common/headline';
import { InputLabel } from '@common/inputLabel';
import { TagTitle } from '@common/tagTitle';
import { TakeOurQuizCTA } from '@components/Hero/TakeOurQuizCTA';

type HeaderImageProps = {
  glp1MedicationIncluded: string;
  image: StaticImageData;
  altText: string;
};

export const ProgrammeCardHeaderImage = ({
  glp1MedicationIncluded,
  image,
  altText,
}: HeaderImageProps): JSX.Element => (
  <div className="relative block h-[284px]">
    <Image layout="fill" objectFit="cover" src={image} alt={altText} />
    <div className="absolute bottom-6 left-6">
      <div className="flex">
        {glp1MedicationIncluded === 'Yes' && (
          <div className="mr-2 rounded-lg bg-teal-200 p-2 text-teal">
            <TagTitle>Weekly injection</TagTitle>
          </div>
        )}
        <div className="rounded-lg bg-dawn-blue-200 p-2 text-dawn-blue">
          <TagTitle>Weight-loss programme</TagTitle>
        </div>
      </div>
    </div>
  </div>
);

type StatsRowProps = {
  label: string;
  value: string | JSX.Element;
};

export const StatsRow = ({ label, value }: StatsRowProps): JSX.Element => (
  <div className="flex justify-between border-b border-dawn-blue-200 py-3">
    <BodySmall>{label}</BodySmall>
    <H6>{value}</H6>
  </div>
);

type PricingProps = {
  priceHeading: string;
  priceSubheading?: string;
};

export const PricingSection = ({
  priceHeading,
  priceSubheading,
}: PricingProps): JSX.Element => (
  <>
    <H4>{priceHeading}</H4>
    {priceSubheading && (
      <div className="mt-1">
        <InputLabel>{priceSubheading}</InputLabel>
      </div>
    )}
  </>
);

type CTAProps = {
  glp1MedicationIncluded: string;
  utmMedium?: string;
  utmCampaign?: string;
};

export const CTASection = ({
  glp1MedicationIncluded,
  utmMedium,
  utmCampaign,
}: CTAProps): JSX.Element => (
  <div className="mt-5">
    <TakeOurQuizCTA
      ctaText={
        glp1MedicationIncluded === 'Yes'
          ? 'Check my eligibility'
          : 'Take our quiz'
      }
      location="Programme card"
      utmCampaign={utmCampaign}
      utmMedium={utmMedium}
    />
  </div>
);
