import { StaticImageData } from 'next/image';

import {
  ProgrammeCardHeaderImage,
  StatsRow,
  CTASection,
} from './ProgrammeCardComponents';

type Props = {
  glp1MedicationIncluded: string;
  image: StaticImageData;
  altText: string;
  weightLossPercentage: string | JSX.Element;
  utmMedium?: string;
  utmCampaign?: string;
  medicationText: string;
};

export const ProgrammeCardNoPricing = ({
  glp1MedicationIncluded,
  image,
  altText,
  weightLossPercentage,
  utmMedium,
  utmCampaign,
  medicationText,
}: Props): JSX.Element => (
  <div className="h-[570px] w-[351px] overflow-hidden rounded-3xl bg-mono-white lg:h-[570px] lg:w-[424px]">
    <ProgrammeCardHeaderImage
      altText={altText}
      glp1MedicationIncluded={glp1MedicationIncluded}
      image={image}
    />
    <div className="p-6 pb-8 text-blue">
      <StatsRow
        label="Average weight loss after 12 months"
        value={weightLossPercentage}
      />
      <StatsRow label={medicationText} value={glp1MedicationIncluded} />
      <StatsRow label="Ongoing 1-1 support from a nutritionist" value="Yes" />
      <div className="mt-8 flex flex-col items-center">
        <CTASection
          glp1MedicationIncluded={glp1MedicationIncluded}
          utmCampaign={utmCampaign}
          utmMedium={utmMedium}
        />
      </div>
    </div>
  </div>
);
